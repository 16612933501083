import { Dialog, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useRef } from 'react'

const AutodeskForgeViewer = ({ openDialog, setOpenDialog }) => {
  const urn = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Ymltc3RvcmFnZTIvT0ZPX1BCX0F1dG9kZXNrX05hdmlzd29ya3Mubndk";
  const viewerDiv = useRef(null);
  const modelTreeDiv = useRef(null);

  const getInitialTokens = async () => {
    const response = await fetch('https://developer.api.autodesk.com/authentication/v2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_AUTODESK_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTODESK_CLIENT_SECRET,
        grant_type: 'client_credentials',
        scope: 'data:read data:write data:create bucket:create bucket:read',
      }),
    });

    const data = await response.json();
    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token, // Note: Client credentials grant does not return a refresh token. Modify according to your auth flow.
      expiresIn: data.expires_in,
    };
  };

  useEffect(async () => {
    if (!openDialog) return;
    const test = await getInitialTokens();
    const options = {
      env: 'AutodeskProduction',
      accessToken: test.accessToken,
    };

    Autodesk.Viewing.Initializer(options, () => {
      if (!viewerDiv.current) {
        console.error("Viewer div not found");
        return;
      }

      const viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv.current);
      viewer.start();

      const documentId = `urn:${urn}`; // Add `urn:` prefix to the URN
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

      function onDocumentLoadSuccess(doc) {
        const viewables = doc.getRoot().getDefaultGeometry();
        viewer.loadDocumentNode(doc, viewables).then(() => {
          console.log('Model loaded successfully');
          showModelTree(viewer);
        });
      }

      function onDocumentLoadFailure(viewerErrorCode) {
        console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
      }

      function showModelTree(viewer) {
        const model = viewer.model;
        const tree = model.getInstanceTree();

        if (!tree) {
          console.error('Model tree not available');
          return;
        }

        const rootId = tree.getRootId();
        const modelTreeContainer = modelTreeDiv.current;
        modelTreeContainer.innerHTML = ''; // Clear previous contents

        tree.enumNodeChildren(rootId, function (childId) {
          const nodeName = tree.getNodeName(childId);
          const listItem = document.createElement('li');
          listItem.textContent = nodeName;
          modelTreeContainer.appendChild(listItem);
        }, true);
      }
    });
  }, [openDialog]);

  return (
    <Dialog className='position-relative' fullScreen open={openDialog} onClose={() => setOpenDialog(prev => !prev)}>
      <IconButton edge="start" color="inherit" onClick={() => setOpenDialog(prev => !prev)} aria-label="close" className='position-absolute' style={{ top: 0, left: 20, zIndex: 5 }}>
        <Close />
      </IconButton>
      <div style={{ display: 'flex' }}>
        <div ref={viewerDiv} style={{ width: '75%', height: '100vh' }}></div>
        <div style={{ width: '25%', height: '100vh', overflowY: 'auto', padding: '10px' }}>
          <ul ref={modelTreeDiv}></ul>
        </div>
      </div>
    </Dialog>
  )
}

export default AutodeskForgeViewer